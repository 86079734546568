@import '~assets/scss/abstracts/variables';
@import '~assets/scss/abstracts/fonts';
@import '~assets/scss/abstracts/mixins';

$color-information-bg: #F9C309;
$color-shadow-public: #D8D1C4;
$color-grey-public: #8A8A8A;
$color-light-grey-public: #B9B9B9;
$color-soft-grey-public: #F3F0EB;
$color-banner-header-bg: rgba(#404041, 0.4);

.button {
  min-width: 60%;
  height: 4.5rem;
  border: 0.2rem solid $color-white;
  border-radius: 2.3rem;
  outline: none;
  background: none;
  color: $color-white;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background-color: rgba($color-white, 0.2);
    color: $color-white;
  }
}

.header {
  margin: 6rem 0;
  color: $color-primary-green;
  font-family: 'Poppins', sans-serif;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
}

.banner {
  display: grid;
  position: relative;
  height: 54rem;
  overflow: hidden;

  .bannerImageContainer {
    width: 100%;
    height: 100%;

    .bannerImage {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .bannerHeader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 82rem;
    max-width: 100%;
    padding: 1rem;
    transform: translate(-50%, 0);
    background-color: $color-banner-header-bg;
    color: $color-white;
    font-family: 'Poppins', sans-serif;
    font-size: 4.6rem;
    font-weight: 600;
    text-align: center;
  }
}

.information {
  padding: 4rem;
  background-color: $color-information-bg;
  color: $color-white;
  text-align: center;

  .informationText {
    width: 100rem;
    max-width: 100%;
    margin: 0 auto 2rem auto;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
  }

  .informationButton {
    min-width: 30rem;
  }
}

.services {
  padding: 0 2rem 6rem 2rem;
  background-color: $color-soft-grey-public;

  .serviceBoxes {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 136rem;
    margin: 0 auto;

    @include media-breakpoint-down('lg') {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-down('md') {
      grid-template-columns: 1fr;
    }

    .serviceBox {
      display: grid;
      justify-items: center;
      padding: 3rem;
      border-radius: 1.4rem;
      background-color: $color-white;
      box-shadow: 0 0 1rem $color-shadow-public;
      text-align: center;
    }

    .serviceTitle {
      padding: 0 2rem;
      font-family: 'Poppins', sans-serif;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }

    .serviceDesc {
      color: $color-grey-public;
      font-size: 1.5rem;
      text-align: left;
    }

    .serviceImage {
      height: 9rem;
      margin: 0 auto 2rem auto;
    }
  }

  .serviceButton {
    width: 60%;
    margin-top: auto;
    border-color: $color-black;
    color: $color-black;

    &:hover {
      border-color: $color-primary;
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.about {
  padding: 6rem 2rem;
  background-color: $color-white;

  .aboutContainer {
    display: grid;
    grid-column-gap: 6.4rem;
    grid-row-gap: 2rem;
    grid-template-columns: auto auto;
    max-width: 136rem;
    margin: 0 auto;

    @include media-breakpoint-down('md') {
      grid-template-columns: 1fr;

      .aboutHeader {
        text-align: center;
      }

      .aboutImage {
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  .aboutImage {
    width: 100%;
    border-radius: 1.3rem;
  }

  .aboutHeader {
    margin-bottom: 2rem;
    color: $color-primary-green;
    font-size: 4rem;
    font-weight: 700;
  }

  .aboutText {
    color: $color-grey-public;
    font-size: 1.5rem;
  }
}

.references {
  padding: 0 2rem 6rem 2rem;

  .referenceContainer {
    display: grid;
    grid-column-gap: 6rem;
    grid-row-gap: 6rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 60rem;
    max-width: 100%;
    margin: 0 auto;
    justify-items: center;

    @include media-breakpoint-down('md') {
      grid-template-columns: 1fr 1fr;
    }
  }

  .referenceCompany {
    margin: 0.7rem 0;
    color: $color-light-grey-public;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.contact {
  padding: 0.5rem 4rem 8.5rem 4rem;
  background-color: $color-primary-green;

  .contactHeader {
    margin: 6rem 0;
    color: $color-white;
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
  }

  .contactForm {
    width: 51rem;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .contactInputContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;

    @include media-breakpoint-down('md') {
      display: block;
    }
  }

  .contactInput,
  .contactMessage {
    width: 100%;
    margin-bottom: 2rem;
    padding: 0.8rem 1rem;
    border: 0;
    border-radius: 0.4rem;

    @include media-breakpoint-down('md') {
      width: 100%;
    }
  }

  .contactButton {
    width: 30rem;
    margin: 2rem auto 0 auto;

    @include media-breakpoint-down('sm') {
      width: 100%;
    }
  }
}

@include media-breakpoint-down('md') {
  .banner .bannerHeader,
  .header {
    font-size: 3rem;
  }

  .information .informationText {
    font-size: 2rem;
  }
}
