@import '~assets/scss/abstracts/variables';

.layout {
  .ant-tabs {
    margin: 0.5rem -1rem;
    padding: 1rem;
    border-bottom-right-radius: 1.9rem;
    border-bottom-left-radius: 1.9rem;
    color: $color-dark;

    &.ant-tabs-card {
      .ant-tabs-nav {
        margin-bottom: 0;

        .ant-tabs-tab {
          min-width: 10rem;
          border: 0.1rem solid transparent;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          text-align: center;

          .ant-tabs-tab-btn {
            margin: 0 auto;
            font-size: 1.3rem;
            font-weight: 800;
          }

          &:hover {
            color: $color-white;
          }
        }

        .ant-tabs-tab-active {
          border-bottom-color: transparent;

          &,
          &:hover {
            color: $color-secondary;
          }
        }
      }
    }

    &-content {
      padding: 1.5rem;
      border: 0.1rem solid $color-light-grey;
      border-top: 0;
      border-top-right-radius: 1.9rem;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      background-color: $color-white;
      box-shadow: 0 0.3rem 0.6rem $color-shadow;
    }

    &-bar {
      margin-bottom: 0;
      border-color: $color-light-grey;
    }
  }
}
