@import './ant-variables';

$color-primary: #FDD60C;
$color-primary-green: #00847F;
$color-secondary: #FEB101;
$color-dark: #191A1E;
$color-white: #FFFFFF;
$color-black: #000000;
$color-dark-grey: #D5D0D0;
$color-light-grey: #F5F5F6;
$color-shadow: rgba($color-dark, 0.1);
$color-red: #FF0000;
$color-red-light: #FF6759;
$color-red-light-bg: rgba($color-red, 0.2);
$color-blue: #0093D1;
$color-dropdown-shadow: rgba($color-dark, 0.5);
$menu-height: 5rem;
$color-input-icon: rgba($color-black, 0.45);

// Estimate
$estimate-color-yellow: $primary-color;
$estimate-color-orange: #FFA200;
$estimate-color-red: #E93426;
$estimate-color-green: #8AC012;
$estimate-color-grey: #9F9F9F;

$estimate-status-map: (
  'NewData': $estimate-color-yellow,
  'NoNewData': $estimate-color-orange,
  'NoData': $estimate-color-red,
  'Ready': $estimate-color-green,
  'Run': $estimate-color-grey,
);

$checkbox-color-map: (
  'Green': #00E769,
  'GreenDark': #00A1AB,
  'BlueLight': #38EBFF,
  'Blue': #2CADF6,
  'BlueDark': #552CF6,
  'BlueDarkest': #0C285C,
  'Yellow': #F9C309,
  'Orange': #FF9B27,
  'Red': #DF4557,
  'Purple': #802066,
  'Beige': #DEADA1,
  'BrownLight': #BB7365,
  'OrangeLight': #FF8362
);
