@import '~assets/scss/abstracts/variables';

.languageSelectorDropdown {
  padding: 1.5rem 0;
}

.languageSelector {
  color: $color-white;
  cursor: pointer;

  .languageSelectorImage,
  .languageSelectorText {
    margin-right: 1rem;
  }

  .languageSelectorImage {
    width: 1.6rem;
  }
}
