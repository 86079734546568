.ant-form {
  .ant-form-item-with-help {
    margin-bottom: 0;
  }

  .ant-row {
    flex: auto;

    .ant-form-item {
      margin-right: 0;
    }
  }
}
