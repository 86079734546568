@import '~assets/scss/abstracts/variables';

.pageHeader {
  margin-bottom: 0;
  color: $color-dark;
  font-size: 2.5rem;
  font-weight: 800;
}

.pageContent {
  margin-top: 1.5rem;
  padding: 2rem;
  border-radius: 1.4rem;
  background: $color-white 0% 0% no-repeat;
  box-shadow: 0 0.3rem 0.6rem $color-shadow;
}

.modalDesc {
  margin: 0;
  font-weight: 400;
}
