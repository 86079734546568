@import '~assets/scss/abstracts/variables';
@import '~assets/scss/abstracts/mixins';

.menu {
  border: none;
  background-color: inherit;
  font-weight: 500;

  .subMenu,
  .menuItem {
    margin: 0;
    color: $color-white;
  }

  .subMenu {
    background-color: inherit;
  }

  .subMenuTitle {
    margin-right: 1rem;
  }
}

:global {
  .ant-menu-submenu {
    .ant-menu-item {
      &.ant-menu-item-selected {
        background-color: rgba($color-primary, 0.15);
      }
    }
  }

  .ant-menu-inline .ant-menu-sub {
    background-color: inherit;
  }
}

.menuPopup {
  border-bottom-right-radius: 1.4rem;
  border-bottom-left-radius: 1.4rem;

  :global {
    .ant-menu {
      padding: 0.5rem 0;
      border-bottom-right-radius: 1.4rem;
      border-bottom-left-radius: 1.4rem;

      .ant-menu-item {
        margin: 0;
        padding: 0 2rem;
        color: $color-dark;
        font-size: 1.3rem;
        font-weight: 600;

        &:hover {
          color: $color-secondary;
        }

        &:active {
          color: $color-secondary;
        }
      }

      &:empty {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down('sm') {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .subMenu {
      width: 100%;
      background: $color-dark;
    }
  }
}

@include media-breakpoint-down('md') {
  :global {
    .ant-menu {
      .ant-menu-item {
        &.ant-menu-item-selected {
          background-color: rgba($color-primary, 0.15);
        }
      }
    }
  }
}
