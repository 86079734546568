@import '~assets/scss/abstracts/variables';

.login {
  align-items: center;
  justify-content: center;

  :global {
    .ant-modal-content {
      background-color: transparent;

      .ant-modal-body {
        padding: 0;
        overflow: hidden;
        border-radius: 1rem;
      }
    }
  }

  .logo {
    display: block;
    width: 12rem;
  }

  .title {
    margin: 1rem 0 2rem;
    color: $color-white;
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    text-align: center;
  }

  .formWrapper {
    min-height: 33rem;
    padding: 2rem;
    padding-bottom: 3rem;
    background: url(~assets/img/login-bg.svg) no-repeat left top;

    .submit {
      display: block;
      width: 15rem;
      margin: 2.5rem auto 0 auto;
      color: $color-white;
      font-size: 1.4rem;

      &,
      &:focus {
        border: 0.2rem solid $color-white;
        color: $color-white;
      }

      &:hover {
        border-color: $color-black;
        color: $color-black;
      }
    }
  }

  .card {
    border: none;
    background-color: $color-white;

    .ant-card-head-title {
      padding: 0.5rem 0 2rem;
    }

    .ant-card-body {
      padding-bottom: 0.5rem;
    }
  }

  .alert {
    margin-bottom: 2rem;
  }

  .username,
  .password {
    margin-bottom: 1rem;

    .ant-form-item-explain {
      padding-top: 0.5rem;
    }
  }

  .loginBottom {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
}
