@import '~assets/scss/abstracts/variables';
@import '~assets/scss/abstracts/mixins';

.header {
  display: flex;
  position: sticky;
  z-index: 10;
  top: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: $menu-height;
  padding: 0 1.5rem;
  background-color: $color-primary-green;

  &.dark {
    background-color: $color-dark;
  }

  .authMenuItem {
    cursor: pointer;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: $menu-height;
    white-space: nowrap;
  }

  .logo {
    width: 9rem;
  }

  .navigation {
    display: none;
    width: 100%;
    height: 5rem;
  }

  .menuToggle {
    @include menu-toggle;
  }

  .authMenu {
    align-items: center;
    margin-bottom: 0;
    list-style-type: none;
  }

  .link,
  .loggedIn {
    padding: 1.5rem 0;
    color: $color-white;
    font-size: 1.3rem;
  }

  .loggedIn {
    padding-right: 0.5rem;
  }

  .link {
    display: block;

    &:hover {
      color: $color-secondary;
    }
  }

  .icon {
    margin-right: 1rem;
  }

  .selectProfile {
    width: 14.5rem;

    :global {
      .ant-select-selection-item,
      .ant-select-selection-search-input,
      .ant-select-selection-placeholder {
        opacity: 1;
        color: $color-white;
      }
    }
  }

  .selectProfileItem {
    margin: 0 1rem 0 2rem;

    :global(.ant-select-clear) {
      border-radius: 50%;
    }
  }

  .sysAdminMenuTitle {
    align-self: center;
    margin: 0 0.5rem;
    color: $color-white;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0;
  }

  @include media-breakpoint-down('md') {
    &.open {
      height: auto;

      .navigation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
      }
    }

    .authMenuItem {
      padding: 1rem 2rem;
    }
  }

  @include media-breakpoint-up('lg') {
    flex-direction: row;
    height: $menu-height;

    .logoContainer {
      width: auto;
      margin-right: 2rem;
    }

    .menuToggle {
      display: none;
    }

    .navigation {
      display: flex;
    }

    .authMenu {
      display: flex;
      margin-left: auto;

      .authMenuItem + .authMenuItem {
        margin-left: 2.5rem;
      }
    }
  }
}

@include media-breakpoint-down('lg') {
  .menuItemText {
    display: none;
  }
}
