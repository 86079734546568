.confirmModalWidget {
  :global {
    .ant-modal-content {
      border-radius: 1rem;
    }

    .ant-modal-confirm-title {
      font-size: 1.6rem;
    }

    .ant-modal-body {
      padding: 2rem;
    }

    .ant-modal-confirm-btns {
      button + button {
        margin-left: 1.2rem;
      }
    }
  }
}

.modalWidget {
  :global {
    .ant-modal-header {
      padding: 2rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;

      .ant-modal-title {
        font-size: 1.6rem;
      }
    }

    .ant-modal-content {
      border-radius: 1rem;

      .ant-modal-close-x {
        font-size: 1.6rem;
      }
    }

    .ant-modal-body {
      padding: 2rem;
    }

    .ant-modal-footer {
      padding: 1.2rem;

      .ant-btn + .ant-btn {
        margin-left: 1.2rem;
      }
    }
  }
}
