@import '~assets/scss/abstracts/variables';
@import '~assets/scss/abstracts/mixins';

.footer {
  display: flex;
  align-items: center;
  background-color: $color-dark;

  @include media-breakpoint-down('md') {
    flex-direction: column;
    text-align: center;
  }

  .brand {
    margin: 1.5rem 4rem;
    color: $color-white;
  }

  .menu {
    display: flex;
    flex-direction: row;
    margin: 0;
    list-style-type: none;

    @include media-breakpoint-down('md') {
      flex-direction: column;
    }
  }

  .footerLink {
    color: $color-white;
  }

  .menuItem {
    margin-right: 2rem;
    padding: 1.5rem 0;

    @include media-breakpoint-down('md') {
      margin-right: 0;
      padding: 1rem 0;
    }
  }
}
