.formWidget {
  :global {
    .ant-form-item-explain {
      padding-top: 0.5rem;
    }

    .ant-form-item-label label {
      height: auto;
    }

    .ant-picker {
      width: 100%;
    }
  }
}
