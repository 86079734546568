@import '~assets/scss/abstracts/variables';

.formItemWidget {
  :global(.ant-form-item-label) {
    font-weight: 600;
  }

  .infoIcon {
    color: $color-input-icon;
  }
}
