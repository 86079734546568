/* montserrat-300 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src:
    local('Montserrat Light'),
    local('Montserrat-Light'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-300.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-300.woff') format('woff');
}

/* montserrat-300italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src:
    local('Montserrat Light Italic'),
    local('Montserrat-LightItalic'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-300italic.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-300italic.woff')
    format('woff');
}

/* montserrat-regular - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-regular.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-regular.woff')
    format('woff');
}

/* montserrat-italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src:
    local('Montserrat Italic'),
    local('Montserrat-Italic'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-italic.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-italic.woff')
    format('woff');
}

/* montserrat-500italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src:
    local('Montserrat Medium Italic'),
    local('Montserrat-MediumItalic'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-500italic.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-500italic.woff')
    format('woff');
}

/* montserrat-600 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src:
    local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-600.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-600.woff') format('woff');
}

/* montserrat-600italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src:
    local('Montserrat SemiBold Italic'),
    local('Montserrat-SemiBoldItalic'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-600italic.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-600italic.woff')
    format('woff');
}

/* montserrat-500 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src:
    local('Montserrat Medium'),
    local('Montserrat-Medium'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-500.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-500.woff') format('woff');
}

/* montserrat-700 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-700.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-700.woff') format('woff');
}

/* montserrat-700italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src:
    local('Montserrat Bold Italic'),
    local('Montserrat-BoldItalic'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-700italic.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-700italic.woff')
    format('woff');
}

/* montserrat-800 - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold'),
    local('Montserrat-ExtraBold'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-800.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-800.woff') format('woff');
}

/* montserrat-800italic - latin_latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold Italic'),
    local('Montserrat-ExtraBoldItalic'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-800italic.woff2')
    format('woff2'),
    url('~assets/fonts/montserrat-v14-latin_latin-ext-800italic.woff')
    format('woff');
}

/* poppins-300 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src:
    local('Poppins Light'),
    local('Poppins-Light'),
    url('~assets/fonts/poppins-v9-latin-ext_latin-300.woff2') format('woff2'),
    url('~assets/fonts/poppins-v9-latin-ext_latin-300.woff') format('woff');
}

/* poppins-regular - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src:
    local('Poppins Regular'),
    local('Poppins-Regular'),
    url('~assets/fonts/poppins-v9-latin-ext_latin-regular.woff2')
    format('woff2'),
    url('~assets/fonts/poppins-v9-latin-ext_latin-regular.woff') format('woff');
}

/* poppins-700 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src:
    local('Poppins Bold'),
    local('Poppins-Bold'),
    url('~assets/fonts/poppins-v9-latin-ext_latin-700.woff2') format('woff2'),
    url('~assets/fonts/poppins-v9-latin-ext_latin-700.woff') format('woff');
}
