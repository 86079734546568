@import './breakpoints';

@mixin menu-toggle($toggle-size: 2rem) {
  top: 0;
  left: 1.4rem;
  width: $toggle-size;
  height: $toggle-size;
  margin-right: 1.5rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  .toggleLine {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: $toggle-size / 10;
    transform: rotate(0deg);
    transform-origin: left center;
    transition: 0.25s ease-in-out;
    opacity: 1;
    background: $color-white;
  }

  .toggleLine:nth-child(1) {
    top: 0.4rem;
  }

  .toggleLine:nth-child(2) {
    top: 1rem;
  }

  .toggleLine:nth-child(3) {
    top: 1.6rem;
  }

  &.open {
    .toggleLine:nth-child(1) {
      top: 0.4rem;
      left: 0.5rem;
      transform: rotate(45deg);
    }

    .toggleLine:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    .toggleLine:nth-child(3) {
      top: 1.9rem;
      left: 0.5rem;
      transform: rotate(-45deg);
    }
  }
}

@function breakpoint-next($name, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);

  @if not $n {
    @error 'breakpoint `#{$name}` not found in `#{$breakpoints}`';
  }

  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

@function breakpoint-min($name) {
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

@function breakpoint-max($name) {
  $next: breakpoint-next($name);

  @return if($next, breakpoint-min($next) - 0.02, null);
}

@mixin media-breakpoint-up($name) {
  $min: breakpoint-min($name);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    @content;
  }
}

@mixin media-breakpoint-down($name) {
  $max: breakpoint-max($name);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  }

  @else {
    @content;
  }
}

@mixin estimate-checkbox($status-map) {
  @each $name, $status in $status-map {
    .#{$name} {
      &:global(.ant-checkbox-wrapper) {
        margin-bottom: 0.9rem;

        + :global(.ant-checkbox-wrapper) {
          margin-left: 0;
        }

        :global {
          display: flex;
          align-items: center;

          span + span {
            margin-left: 0.5rem;
            color: $color-dark;
            font-size: 1.3rem;
          }

          .ant-checkbox {
            top: 0.1rem;

            &.ant-checkbox-checked {
              &::after {
                border-color: $status;
              }

              .ant-checkbox-inner {
                border-color: $status;
                background-color: $status;
              }
            }

            .ant-checkbox-inner {
              width: 2.2rem;
              height: 2.2rem;

              &::after {
                zoom: 1.3;
              }
            }
          }
        }
      }
    }
  }
}

@mixin estimate-circles($status-map) {
  $not-clikable: 'Run', 'NoData';

  @each $name, $status in $status-map {
    &.#{$name} {
      background-color: $status;

      @if (index($not-clikable, $name) == null) {
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 0 2rem rgba($status, 0.3);
        }

        &:active {
          box-shadow: 0 0 0 2.3rem rgba($status, 0.5);
        }
      }
    }
  }
}

@mixin colored-checkbox($color-map) {
  @each $name, $color in $color-map {
    .checkBox#{$name} {
      :global {
        .ant-checkbox {
          &.ant-checkbox-checked {
            &::after {
              border-color: $color;
            }

            .ant-checkbox-inner {
              border-color: $color;
              background-color: $color;
            }
          }
        }

        .ant-radio {
          &.ant-radio-checked {
            &::after {
              border-color: $color;
            }

            .ant-radio-inner {
              border-color: $color;
              background-color: $color;

              &::after {
                top: 0.4rem;
                left: 0.4rem;
                width: 0.6rem;
                height: 0.6rem;
                background-color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}
