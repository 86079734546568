@import '~assets/scss/abstracts/variables';

.selectWidget {
  width: 100%;

  :global {
    .ant-select-selection-placeholder {
      font-weight: 500;
    }
  }
}
