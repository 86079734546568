.layout {
  .ant-pagination {
    &.ant-table-pagination {
      margin-bottom: 0;

      .ant-pagination-item-active a {
        color: $color-white;
        font-weight: 600;
      }

      .ant-pagination-item,
      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        border-radius: 50%;
      }
    }
  }
}
