@import '~assets/scss/abstracts/variables';

%dropdown-placement-common {
  content: '';
  position: absolute;
  z-index: 1;
  top: -0.8rem;
  border-width: 0 0.8rem 0.8rem;
  border-style: solid;
  border-color: transparent transparent $color-white;
}

.ant-dropdown {
  $filter-dropdown: '.ant-table-filter-dropdown';

  z-index: 10;

  #{$filter-dropdown} {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 1.5rem $color-dropdown-shadow;

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;

      .ant-checkbox-group-item {
        margin: 0 0 1rem 0;
      }
    }

    .ant-divider {
      margin: 0 0 1rem 0;
    }
  }

  &-placement-bottomRight {
    #{$filter-dropdown} {
      &::before {
        @extend %dropdown-placement-common;

        right: 0.8rem;
      }
    }
  }

  &-placement-bottomLeft {
    #{$filter-dropdown} {
      &::before {
        @extend %dropdown-placement-common;

        right: inherit;
        left: 0.8rem;
      }
    }
  }

  .filter-search {
    margin-right: 0.8rem;
  }

  .filter__input {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }
}
