@import '~assets/scss/abstracts/variables';

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
}

.root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
}

.page {
  width: 100%;
  height: calc(100% - 1.5rem);
  padding: 1.5rem;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;

  .action__button {
    .ant-btn[disabled] {
      border: 0.1rem solid rgba($color-dark, 0.1);
      background-color: $color-dark-grey;
    }

    + .action__button {
      margin-left: 1rem;
    }
  }
}
